@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom CSS code goes here */
html,
body {
  scroll-behavior: smooth;
}

/*************** FILE UPLOAD START  ***************/

.file-upload {
  display: block;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}
label span {
  font-size: 12px;
  text-transform: none;
}
.file-upload .file-select {
  display: block;
  /* border: 2px solid #dce4ec; */
  color: #34495e;
  cursor: pointer;
  height: 58px;
  line-height: 58px;
  text-align: left;
  background: #ffffff;
  /* overflow: hidden; */
  cursor: pointer;
}
.file-upload .file-select .file-select-button {
  background: #352c2b;
  padding: 0 10px;
  display: inline-block;
  height: 56px;
  line-height: 58px;
  color: white;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.file-upload .file-select .file-select-name {
  line-height: 58px;
  display: inline-block;
  padding: 0 10px;
}
.file-upload .file-select.error {
  border: 1px solid #ff5c4c !important;
}
.file-upload .file-select:hover {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select:hover .file-select-button {
  background: #7d7d7d;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}
.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  /* height: 50px; */
  line-height: 50px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 100%;
  line-height: 50px;
}
.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 50px;
  display: inline-block;
  padding: 0 10px;
}
/*************** FILE UPLOAD END  ***************/

@layer components {
  .quote-symbol .slider-content-wrapper:before {
    content: "\f10d";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 60px;
    position: absolute;
    top: 7px;
  }
  .swipper-arrows {
    color: #333333;
  }
  .swipper-arrows,
  .swipper-arrows {
    top: 39%;
  }
  .swipper-arrows:before,
  .swipper-arrows:after {
    font-size: 40px;
  }
  .slider-content-wrapper {
    max-width: 800px;
    margin: 0 auto 85px;
    box-shadow: 0px 30px 60px 0px #0000001a;
    padding: 85px 35px 55px;
  }
  .swiperSlider {
    max-width: 1000px;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    .nav-menu {
      /* max-height: 0 !important; */
      transition: 0.3s ease-in-out;
    }
    .nav-menu ul {
      max-height: 0;
      transition: 0.3s;
      overflow-y: hidden;
    }
    .nav-menu.opened ul {
      max-height: 500px;
      transition: 0.3s;
    }

    .nav-menu.opened {
      max-height: 1000px !important;
    }
  }
}

/**************** FORMS ERROR ****************/

input.error,
textarea.error,
select.error {
  border: 1px solid #ff5c4c;
}

label.error {
  font-size: 14px;
  position: relative;
  top: -20px;
  color: #ff5c4c;
}

label.error:before {
  content: "\f06a";
  font-family: "FontAwesome";
  padding-right: 7px;
  font-size: 14px;
}

label#msg-error {
  top: 2px;
}
label#file-error {
  top: calc(0100% + -15px);
  left: 0;
  position: absolute;
}
.file-select.error {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .file-upload .file-select,
  .file-upload .file-select .file-select-name,
  .file-upload .file-select,
  .file-upload .file-select .file-select-button {
    line-height: 42px;
    height: 42px;
  }
  label#message-error {
    top: 0;
  }

  label#file-error {
    top: calc(100% - 6px);
  }
}
